import React from "react";
import Topbar from "../components/Topbar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

const MainLayout = () => {
  const { pathname } = useLocation();
  return (
    <div className={pathname === "/about" ? "mainLayout alt" : "mainLayout"}>
      <Topbar />
      <Outlet />
      <Contact />
      <Footer />
    </div>
  );
};

export default MainLayout;
