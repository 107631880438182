import React from "react";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { t } = useTranslation();

  const text = t("auth.resetP", { returnObjects: true });

  return (
    <div className="signup">
      <div className="signup__main">
        <h1>{text.reset}</h1>

        <p style={{ fontSize: 14, marginBottom: 30 }}>{text.enter}</p>

        <div className="signup__form">
          <div className="signup__form__item">
            <input type="email" placeholder={text.email} />
          </div>

          <div className="signup__form__btn" style={{ marginTop: 30 }}>
            <button style={{ width: "170px" }} className="btn">
              <span>{text.send}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
