import contact1 from "../assets/images/svg/contact1.svg";
import contact2 from "../assets/images/svg/contact2.svg";
import contact3 from "../assets/images/svg/contact3.svg";
// import Instagram from "../assets/images/svg/Instagram.svg";
// import Twitter from "../assets/images/svg/Twitter.svg";
// import Behance from "../assets/images/svg/Behance.svg";
// import Facebook from "../assets/images/svg/Facebook.svg";
// import Dribbble from "../assets/images/svg/Dribbble.svg";
import social1 from "../assets/images/svg/social1.svg";
import social2 from "../assets/images/svg/social2.svg";
import social3 from "../assets/images/svg/social3.svg";
import logoAlt from "../assets/images/svg/logoAlt.svg";
import { useLocation } from "react-router-dom";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import Loading from "react-loading";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const text = t("contact", { returnObjects: true });

  const { pathname } = useLocation();

  const form = useRef();
  const nameInput = useRef();
  const emailInput = useRef();
  const subjectInput = useRef();
  const messageInput = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_service_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLICK_KEY,
        }
      )
      .then(
        () => {
          setLoading(false);
          nameInput.current.value = "";
          emailInput.current.value = "";
          subjectInput.current.value = "";
          messageInput.current.value = "";
          toast.success(text.right.sent);
        },
        (error) => {
          setLoading(false);
          toast.error(error.text);
        }
      );
  };

  return (
    <section className="contact" id="contact">
      <h2 className="whyWork__main__title">
        <span>{text.title1}</span> {text.title2}
      </h2>

      <div className="contact__main">
        <div className="contact__main__left">
          <h3>{text.left.title}</h3>
          <p className="contact__main__left__disc">{text.left.disc}</p>

          <div className="contact__main__left__info">
            <div className="contact__main__left__info__item">
              <div className="contact__main__left__info__item__left">
                <img src={logoAlt} alt="" />
                <div className="contact__main__left__info__item__left__main">
                  <h5>{text.left.info1Name}</h5>
                  <h5 style={{ marginBottom: 0 }}>{text.left.info1Data}</h5>
                </div>
              </div>

              {/* <div className="contact__main__left__info__item__right">
                <h5>Representative:</h5>
                <h5>Vladimir Radovic</h5>
              </div> */}
            </div>
            <div className="contact__main__left__info__item">
              <div className="contact__main__left__info__item__left">
                <img src={contact2} alt="" />
                <div className="contact__main__left__info__item__left__main">
                  <h5>{text.left.info2Name}</h5>
                  <p>{text.left.info2Data}</p>
                </div>
              </div>

              {/* <div className="contact__main__left__info__item__right">
                <h5>
                  Reg. No: <span style={{ fontWeight: 300 }}>21631094</span>
                </h5>
              </div> */}
            </div>
            <div className="contact__main__left__info__item">
              <div className="contact__main__left__info__item__left">
                <img src={contact1} alt="" />
                <div className="contact__main__left__info__item__left__main">
                  <h5>{text.left.info3Name}</h5>
                  <p>{text.left.info3Data}</p>
                </div>
              </div>
              {/* <div className="contact__main__left__info__item__right">
                <h5>
                  Inception: <span style={{ fontWeight: 300 }}>12.11.2020</span>
                </h5>
              </div> */}
            </div>
            <div className="contact__main__left__info__item">
              <div className="contact__main__left__info__item__left">
                <img src={contact3} alt="" />
                <div className="contact__main__left__info__item__left__main">
                  <h5>{text.left.info4Name}</h5>
                  <p>{text.left.info4Data}</p>
                </div>
              </div>
            </div>
          </div>

          {pathname === "/about" && (
            <div className="contact__main__left__infoAlt">
              <div className="contact__main__left__infoAlt__item">
                <h5>{text.left.info5Name}</h5>
                <h5>{text.left.info5Data}</h5>
              </div>
              <div className="contact__main__left__infoAlt__item">
                <h5>
                  {text.left.info6Name}{" "}
                  <span style={{ fontWeight: 300 }}>{text.left.info6Data}</span>
                </h5>
              </div>
              <div className="contact__main__left__infoAlt__item">
                <h5>
                  {text.left.info7Name}{" "}
                  <span style={{ fontWeight: 300 }}>{text.left.info7Data}</span>
                </h5>
              </div>
            </div>
          )}

          <div
            className={
              pathname === "/about"
                ? "contact__main__left__social alt"
                : "contact__main__left__social"
            }
          >
            {/* <img src={Facebook} alt="" /> */}
            {/* <img src={Twitter} alt="" /> */}
            {/* <img src={Instagram} alt="" /> */}
            {/* <img src={Dribbble} alt="" /> */}
            {/* <img src={Behance} alt="" /> */}
            <a
              href="https://www.instagram.com/metoda_srbija/?utm_source=qr&igsh=cnA1dzhwNGIzZTkx"
              target="_blank"
              rel="noreferrer"
            >
              <img src={social1} alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/metoda-rs/about/?viewAsMember=true"
              target="_blank"
              rel="noreferrer"
            >
              <img src={social2} alt="" />
            </a>
            <a
              href="https://x.com/metoda_srbija"
              target="_blank"
              rel="noreferrer"
            >
              <img src={social3} alt="" />
            </a>
          </div>
        </div>

        <div className="contact__main__right">
          <p className="contact__main__right__disc">{text.right.disc}</p>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="contact__main__right__form"
          >
            <div className="contact__main__right__form__item">
              <input
                type="text"
                placeholder={text.right.name}
                name="name"
                ref={nameInput}
                required
              />
            </div>
            <div className="contact__main__right__form__item alt">
              <input
                type="text"
                placeholder={text.right.email}
                name="email"
                ref={emailInput}
                required
              />
            </div>
            <div className="contact__main__right__form__item">
              <input
                type="text"
                placeholder={text.right.subject}
                name="subject"
                ref={subjectInput}
                required
              />
            </div>
            <div className="contact__main__right__form__item alt">
              <textarea
                placeholder={text.right.message}
                name="message"
                rows={8}
                ref={messageInput}
                required
              ></textarea>
            </div>

            <div className="contact__main__right__form__btn">
              <button type="submit">
                {loading ? (
                  <Loading color="#017283" width={30} height={30} type="spin" />
                ) : (
                  <span>{text.right.send}</span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
