import React from "react";
import work1Icon from "../assets/images/svg/work1.svg";
import work2Icon from "../assets/images/svg/work2.svg";
import work3Icon from "../assets/images/svg/work3.svg";
import work4Icon from "../assets/images/svg/work4.svg";
import work5Icon from "../assets/images/svg/work5.svg";
// import work6Icon from "../assets/images/svg/work6.svg";
import work7Icon from "../assets/images/svg/work7.svg";
import eclipes from "../assets/images/arcWhy.png";
import { HashLink } from "react-router-hash-link";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

const WhyWork = () => {
  const { t } = useTranslation();
  const text = t("whyWork", { returnObjects: true });

  const list = [
    {
      title: t("whyWorkItem1Title"),
      disc: t("whyWorkItem1Disc"),
      img: work1Icon,
    },
    {
      title: t("whyWorkItem2Title"),
      disc: t("whyWorkItem2Disc"),
      img: work2Icon,
    },
    {
      title: t("whyWorkItem3Title"),
      disc: t("whyWorkItem3Disc"),
      img: work3Icon,
    },
    {
      title: t("whyWorkItem4Title"),
      disc: t("whyWorkItem4Disc"),
      img: work4Icon,
    },
    {
      title: t("whyWorkItem5Title"),
      disc: t("whyWorkItem5Disc"),
      img: work5Icon,
    },
    {
      title: t("whyWorkItem6Title"),
      disc: t("whyWorkItem6Disc"),
      img: work7Icon,
    },
  ];

  const handleListImages = (index) => {
    const num = index + 1;
    if (num === 1) {
      return work1Icon;
    } else if (num === 2) {
      return work2Icon;
    } else if (num === 3) {
      return work3Icon;
    } else if (num === 4) {
      return work4Icon;
    } else if (num === 5) {
      return work5Icon;
    } else {
      return work7Icon;
    }
  };

  return (
    <section className="whyWork">
      <div className="whyWork__main">
        <h2 className="whyWork__main__title">
          {text.title1} <span>{text.title2}</span>
        </h2>

        <div className="whyWork__main__arc">
          <img src={eclipes} className="whyWork__main__arc__img" alt="" />
          <p className="whyWork__main__disc">{text.disc}</p>

          <div className="whyWork__main__list">
            {text.list?.map((item, i) => {
              return (
                <div key={item.title} className="whyWork__main__list__item">
                  <img src={handleListImages(i)} alt="" />
                  <h4>{item.title}</h4>
                  <p className={item.small ? "small" : ""}>{item.disc}</p>
                </div>
              );
            })}
          </div>
          <div className="whyWork__main__list__mobile">
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              pagination={true}
              loop={true}
              modules={[Pagination, Navigation]}
              style={{ paddingBottom: 25 }}
              navigation={true}
            >
              {text.list?.map((item, i) => {
                return (
                  <SwiperSlide key={item.title}>
                    <div
                      style={{ width: "70%", margin: "0 auto" }}
                      className="whyWork__main__list__item"
                    >
                      <img src={handleListImages(i)} alt="" />
                      <h4>{item.title}</h4>
                      <p className={item.small ? "small" : ""}>{item.disc}</p>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="whyWork__main__btn">
            <HashLink to="#pricing">
              <button>
                <span>{t("getStartedBtn")}</span>
              </button>
            </HashLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWork;
