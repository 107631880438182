import React from "react";
// import search from "../assets/images/svg/search.svg";
// import faq1 from "../assets/images/svg/faq1.svg";
// import faq2 from "../assets/images/svg/faq2.svg";
// import faq3 from "../assets/images/svg/faq3.svg";
import FaqItem from "./FaqItem";
import { useTranslation } from "react-i18next";

const Faq = ({ faqList, alt, alt2 }) => {
  // const tagList = [
  //   {
  //     name: "General",
  //     img: faq1,
  //   },
  //   {
  //     name: "Account",
  //     img: faq2,
  //   },
  //   {
  //     name: "Payment",
  //     img: faq3,
  //   },
  // ];
  const { t } = useTranslation();
  const text = t("faq.main", { returnObjects: true });

  return (
    <section className={alt ? "faq alt" : alt2 ? "faq alt2" : "faq"}>
      <h2 className="whyWork__main__title">
        {text.title1} <span>{text.title2}</span>
      </h2>

      <p className="faq__disc">{text.disc}</p>

      {/* <div className="faq__search">
        <img src={search} alt="" />
        <div className="faq__search__input">
          <input type="text" placeholder="Ask a question" />
        </div>
        <button>
          <span>Search</span>
        </button>
      </div> */}

      {/* <div className="faq__tags">
        {tagList.map((tag) => {
          return (
            <div className="faq__tags__item" key={tag.name}>
              <div className="faq__tags__item__img">
                <img src={tag.img} alt="" />
              </div>
              <h4>{tag.name}</h4>
            </div>
          );
        })}
      </div> */}

      <div className="faq__list">
        {faqList.map((faq, i) => {
          return <FaqItem faq={faq} index={i} key={faq.question} />;
        })}
      </div>
    </section>
  );
};

export default Faq;
