import React, { useEffect } from "react";
import aboutHb from "../assets/images/svg/aboutHb.svg";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const text = t("about", { returnObjects: true });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about">
      <div className="about__main">
        <div className="about__main__header">
          <div className="about__main__header__main">
            <img src={aboutHb} alt="" />
            <h1>{text.title}</h1>
          </div>
        </div>

        <div className="about__main__disc">
          <h3>{text.subTitle}</h3>

          <p>{text.disc1}</p>
          <p>{text.disc2}</p>
          <p>{text.disc3}</p>
        </div>
      </div>
    </div>
  );
};

export default About;
