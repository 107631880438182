import hero from "../assets/images/domainHero.png";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import domainWhy1 from "../assets/images/svg/domainWhy1.svg";
import domainWhy2 from "../assets/images/svg/domainWhy2.svg";
import domainWhy3 from "../assets/images/svg/domainWhy3.svg";
import domainWhy4 from "../assets/images/svg/domainWhy4.svg";
import arrowLeftD from "../assets/images/svg/arrowLeftD.svg";
import arrowRightD from "../assets/images/svg/arrowRightD.svg";
import important from "../assets/images/svg/important.svg";
import search from "../assets/images/svg/search.svg";
import essMobile from "../assets/images/essMobile.png";
import essMobileS from "../assets/images/essMobileS.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { useEffect, useRef, useState } from "react";
import Faq from "../components/Faq";
import { useTranslation } from "react-i18next";

const Domain = () => {
  const { t, i18n } = useTranslation();

  const text = t("domain", { returnObjects: true });
  const faqList = t("faq.domain.list", { returnObjects: true });

  const [hide, setHide] = useState("left");
  const [hide2, setHide2] = useState("left");
  const [selectedCatHeader, setSelectedCatHeader] = useState("");

  const swiperRef = useRef();
  const swiperRef2 = useRef();

  //   const faqList = [
  //     {
  //       question: "What is domain name?",
  //       answer: `A domain name, often just called a domain, functions much like a physical  home address. It serves as the means by which people locate your website on  the internet, either by typing the domain into a web browser or by using a  search engine. <br/> <br/>
  // Examples of domains include Google.com and Facebook.com. For best results, a domain should ideally be registered to match the name of the brand it represents.`,
  //       html: true,
  //     },
  //     {
  //       question: "Why do I need to buy a domain name? ",
  //       answer: `Purchasing domain names is crucial for enabling potential visitors to discover  your site. In the absence of specific website names, access would require  inputting the IP address, a method far less memorable.<br/> <br/>
  // When conducting a domain search for an apt name, incorporating keywords or  brand names can be beneficial.`,
  //       html: true,
  //     },
  //     {
  //       question: "How Do I Get a Free Domain?",
  //       answer: `Metoda provides the best way to get a great domain for free. Simply sign up for a Premium or Business web hosting plan for 12 months or more, and you'll receive a free domain registration for one year.`,
  //     },
  //     {
  //       question: "What Is Privacy Protection?",
  //       answer: `Privacy protection, also known as WHOIS protection, hides certain information about a  domain name's owner that would otherwise be accessible through a WHOIS lookup. <br/> <br/>
  // This protection allows the domain registrar to replace your name, address, phone  number, email address, and business name with generic, non-identifiable information.`,
  //       html: true,
  //     },
  //     {
  //       question: "How to search for a perfect website name? ",
  //       answer: `Optimal strategies for acquiring domain names involve: <br/> Conciseness: Short names are best. Aim for two to three words <br/> Clarity: Avoid long or challenging-to-spell words. <br/> Relevance: Incorporate a keyword related to your field. For instance, <br/> belgradecoffeebeans.com for a coffee bean seller in Belgrade.<br/> Simplicity: Steer clear of numbers, as they complicate memorability. <br/> Branding: Choose a domain that features your brand name to enhance recognition.`,
  //       html: true,
  //     },
  //     {
  //       question: "How Long does a domain name registration last? ",
  //       answer: `Domain names can be registered for a minimum of one year. At Metoda, options to register domains such as .com, .net, .org, and others extend up to three years. <br />
  // To ensure your domains remain registered in your name and your website stays online continuously, setting up automatic renewal is advised.
  // `,
  //       html: true,
  //     },
  //     {
  //       question: "After my domain is registered, can I change it later? ",
  //       answer: `No, once you register a domain, it cannot be changed. However, you can purchase a new domain name and transfer your website to that domain.
  // `,
  //     },
  //     {
  //       question: "What’s the Difference Between a Domain Name and Web Hosting?",
  //       answer: `Think of your domain name as your home address and web hosting as the physical  structure, like the house. A domain helps people find your site, while hosting provides  the online space to store your website data. To publish a website, you'll need both  domain and hosting services so that visitors can easily find your site on the internet.
  // <br/> <br/> While it’s technically possible to have one without the other, they work best together.  This is why many hosting companies offer domain names, and many domain registrars provide hosting services. `,
  //       html: true,
  //     },
  //     {
  //       question: "How can you check if a domain name is available? ",
  //       answer: `Enter your desired name into the domain name search tool to check its  availability.
  // <br/> <br/>If it's available, you can add it to your cart and purchase the domain. If it's  already taken, we will suggest other great options for you.`,
  //       html: true,
  //     },
  //     {
  //       question: "What are the requirements for buying domain names at Metoda? ",
  //       answer: `Checking domain availability is free, and there are no special requirements for  purchasing a domain name. We only need basic contact information and a valid payment method.
  // <br/> <br/> You can also buy domains from us and point them to another hosting provider.`,
  //       html: true,
  //     },
  //     {
  //       question: "How long does it take to register a domain name? ",
  //       answer: `Metoda offers instant activation, ensuring your desired domain name is ready to use immediately.
  // <br/> <br/> We've streamlined the process, so it takes just a few minutes to go from  checking domain name availability to successfully registering it.`,
  //       html: true,
  //     },
  //     {
  //       question: "Where can I buy a domain? ",
  //       answer: `A range of trusted domain registrars, Metoda included, are available for purchasing a  domain. Selecting a registrar known for excellent customer service and attractive  pricing is crucial. Acquiring a domain marks the beginning of building a robust online identity, making it essential to make an informed choice!`,
  //     },
  //   ];

  useEffect(() => {
    setSelectedCatHeader(text.cat.headers[0].name);
  }, [i18n.language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="domain">
      <section className="domain__search">
        <h1 className="hero__header__title">
          <span>{text.search.title}</span>
        </h1>

        <div className="faq__search">
          <img src={search} alt="" />
          <div className="faq__search__input">
            <input type="text" placeholder={text.search.input} />
          </div>
          <button>
            <span>{text.search.btn}</span>
          </button>
        </div>

        <div className="domain__plan__main">
          <h3>{text.search.subtitle}</h3>

          <div className="domain__plan__main__list">
            <Swiper
              spaceBetween={10}
              slidesPerView={4}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              breakpoints={{
                320: { slidesPerView: 2, spaceBetween: 10 },
                480: { slidesPerView: 2, spaceBetween: 10 },
                768: { slidesPerView: 3, spaceBetween: 10 },
                980: { slidesPerView: 4, spaceBetween: 10 },
              }}
              modules={[Navigation]}
              onActiveIndexChange={(e) => {
                if (e.isBeginning) {
                  setHide("left");
                } else if (e.isEnd) {
                  setHide("right");
                } else {
                  setHide("none");
                }
              }}
            >
              {text.search.list?.map((item) => {
                return (
                  <SwiperSlide key={item.name}>
                    <div className="domain__plan__main__list__item">
                      <h4>{item.name}</h4>

                      <div className="domain__plan__main__list__item__price">
                        <p>
                          {text.search.sign}
                          <b>{item.price}</b>
                        </p>
                        <span>{text.search.first}</span>
                      </div>

                      <button className="btn">
                        <span>{t("getStartedBtn")}</span>
                      </button>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div className="domain__plan__main__list__nav">
              {hide === "none" ? (
                <>
                  <button
                    className="left"
                    onClick={() => swiperRef.current?.slidePrev()}
                  >
                    <img src={arrowLeftD} alt="" />
                  </button>
                  <button onClick={() => swiperRef.current?.slideNext()}>
                    <img src={arrowRightD} alt="" />
                  </button>
                </>
              ) : hide === "left" ? (
                <>
                  <div></div>
                  <button onClick={() => swiperRef.current?.slideNext()}>
                    <img src={arrowRightD} alt="" />
                  </button>
                </>
              ) : (
                <>
                  <button onClick={() => swiperRef.current?.slidePrev()}>
                    <img src={arrowLeftD} alt="" />
                  </button>
                  <div></div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="domain__cat">
        <div className="domain__cat__header">
          {text.cat.headers?.map((item) => {
            return (
              <div
                className={
                  item.name === selectedCatHeader
                    ? "domain__cat__header__item alt"
                    : "domain__cat__header__item"
                }
                onClick={() => setSelectedCatHeader(item.name)}
                key={item.shtName}
              >
                <b>{item.name}</b>
                <span>{item.shtName}</span>
                {item.name === selectedCatHeader && <p>{item.types}</p>}
              </div>
            );
          })}
        </div>

        <div className="domain__cat__title">
          <b>{selectedCatHeader}</b>
          <div className="domain__cat__title__right">
            Sve cene su izrazene bez PDV-a. <span>Prikazi cene sa PDV-om.</span>
          </div>
        </div>

        <div style={{ overflow: "auto", width: "100%", maxWidth: 1000 }}>
          <div className="domain__cat__list">
            <div className="domain__cat__list__top">
              <div
                className="domain__cat__list__top__item"
                style={{
                  width:
                    selectedCatHeader === text.cat.headers[1].name
                      ? "240px"
                      : "140px",
                }}
              >
                {text.cat.tableHead.ext}
              </div>
              <div
                className="domain__cat__list__top__item"
                style={{ width: "300px" }}
              >
                {text.cat.tableHead.type}
              </div>
              <div
                className="domain__cat__list__top__item"
                style={{ width: "130px" }}
              >
                {text.cat.tableHead.price}
              </div>
            </div>

            <div className="domain__cat__list__main">
              {text.cat?.tableData[selectedCatHeader]?.map((item, i) => {
                return (
                  <div className="domain__cat__list__main__item" key={i}>
                    <b
                      style={{
                        width:
                          selectedCatHeader === "International Domains"
                            ? "200px"
                            : "100px",
                      }}
                    >
                      {item.ext}
                    </b>

                    <span style={{ width: "300px" }}>{item.type}</span>
                    {item?.newPrice ? (
                      <div
                        style={{ width: "150px" }}
                        className="domain__cat__list__main__item__new"
                      >
                        <p>
                          {item.price}
                          <span></span>
                        </p>
                        <b>{item.newPrice}</b>
                        <img src={important} alt="" />
                      </div>
                    ) : (
                      <b style={{ width: "120px" }}>{item.price}</b>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="domain__plan__main__list mobile">
          <Swiper
            spaceBetween={10}
            slidesPerView={4}
            onBeforeInit={(swiper) => {
              swiperRef2.current = swiper;
            }}
            breakpoints={{
              320: { slidesPerView: 2, spaceBetween: 10 },
              480: { slidesPerView: 2, spaceBetween: 10 },
              768: { slidesPerView: 3, spaceBetween: 10 },
              980: { slidesPerView: 4, spaceBetween: 10 },
            }}
            modules={[Navigation, Pagination]}
            pagination={true}
            onActiveIndexChange={(e) => {
              if (e.isBeginning) {
                setHide2("left");
              } else if (e.isEnd) {
                setHide2("right");
              } else {
                setHide2("none");
              }
            }}
            style={{ paddingBottom: 45 }}
          >
            {text.cat?.tableData[selectedCatHeader]?.map((item) => {
              return (
                <SwiperSlide key={item.ext} style={{ height: "auto" }}>
                  <div
                    className={
                      item?.newPrice
                        ? "domain__plan__main__list__item alt"
                        : "domain__plan__main__list__item"
                    }
                    style={{ height: "100%", justifyContent: "center" }}
                  >
                    <h4
                      className={
                        item?.small
                          ? "domain__plan__main__list__item__title small"
                          : "domain__plan__main__list__item__title"
                      }
                    >
                      {item.ext}
                    </h4>

                    <div className="domain__plan__main__list__item__price">
                      {item?.newPrice ? (
                        <>
                          <div
                            style={{ justifyContent: "center" }}
                            className="domain__cat__list__main__item__new"
                          >
                            <p>
                              {item.price}
                              <span></span>
                            </p>
                          </div>
                          <p>
                            <b>{item.newPrice}</b>
                          </p>
                        </>
                      ) : (
                        <p>
                          <b>{item.price}</b>
                        </p>
                      )}
                      <span
                        style={{
                          fontSize: 10,
                          lineHeight: "1.2",
                          display: "inline-block",
                          marginTop: 5,
                        }}
                      >
                        {item.type}
                      </span>
                    </div>

                    <button className="btn">
                      <span>Get Started</span>
                    </button>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="domain__plan__main__list__nav alt">
            {hide2 === "none" ? (
              <>
                <button
                  className="left"
                  onClick={() => swiperRef2.current?.slidePrev()}
                >
                  <img src={arrowLeftD} alt="" />
                </button>
                <button onClick={() => swiperRef2.current?.slideNext()}>
                  <img src={arrowRightD} alt="" />
                </button>
              </>
            ) : hide2 === "left" ? (
              <>
                <div></div>
                <button onClick={() => swiperRef2.current?.slideNext()}>
                  <img src={arrowRightD} alt="" />
                </button>
              </>
            ) : (
              <>
                <button onClick={() => swiperRef2.current?.slidePrev()}>
                  <img src={arrowLeftD} alt="" />
                </button>
                <div></div>
              </>
            )}
          </div>
        </div>
      </section>

      {/* <div className="domain__con"> */}
      <section className="domain__why">
        <h2
          className="whyWork__main__title"
          style={{ letterSpacing: "-1px" }}
          dangerouslySetInnerHTML={{ __html: text.why.title }}
        ></h2>

        <p className="domain__why__disc">{text.why.disc}</p>

        <div className="domain__why__list">
          {text.why.list?.map((item, i) => {
            return (
              <div className="domain__why__list__item" key={item.title}>
                <img
                  src={
                    i === 0
                      ? domainWhy3
                      : i === 1
                      ? domainWhy1
                      : i === 2
                      ? domainWhy4
                      : domainWhy2
                  }
                  alt=""
                />
                <div className="domain__why__list__item__main">
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* </div> */}
      <section className="domain__ess">
        <h2
          className="whyWork__main__title"
          style={{ letterSpacing: "-1px" }}
          dangerouslySetInnerHTML={{ __html: text.ess.title }}
        ></h2>

        <div className="domain__ess__list">
          {text.ess.list?.map((item, i) => {
            const num = 1 + i;
            const bottomRow = i > 2 ? true : false;
            if (num % 2 === 0) {
              return (
                <div
                  className={
                    bottomRow
                      ? "domain__ess__list__item btm"
                      : "domain__ess__list__item"
                  }
                  key={item.title}
                >
                  <span>{i + 1}</span>
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              );
            } else {
              return (
                <div
                  className={
                    i === 4
                      ? "domain__ess__list__item btm altB"
                      : bottomRow
                      ? "domain__ess__list__item alt btm"
                      : "domain__ess__list__item alt"
                  }
                  key={item.title}
                >
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                  <span>{i + 1}</span>
                </div>
              );
            }
          })}
        </div>

        <div className="domain__ess__list__mobile">
          <img src={i18n.language === "en" ? essMobile : essMobileS} alt="" />
        </div>
      </section>

      <Faq faqList={faqList} alt2={true} />
    </div>
  );
};

export default Domain;
