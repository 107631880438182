import React from "react";
import arrowRight from "../assets/images/svg/arrowRight.svg";
import arrowUp from "../assets/images/svg/arrowUp.svg";
import arrowUpAlt from "../assets/images/svg/arrowUpAlt.svg";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";

const Service = () => {
  const { t } = useTranslation();
  const text = t("service", { returnObjects: true });

  return (
    <section className="service">
      <h2 className="whyWork__main__title">
        {text.title1} <span>{text.title2}</span> {text.title3}{" "}
        <span>{text.title4}</span>
      </h2>

      <p className="service__disc">{text.disc}</p>

      <div className="service__list alt">
        {text.list?.map((item) => {
          return (
            <div key={item.title} className="service__list__item">
              <div className="service__list__item__main">
                <div className="service__list__item__left">
                  <h3>{item.title}</h3>
                  <p>{item.disc}</p>
                </div>
                <div className="service__list__item__center">
                  <ul className="service__list__item__center__links">
                    {item.types.map((item) => {
                      return (
                        <li key={item}>
                          {item} <img src={arrowRight} alt="" />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="service__list__item__right">
                <Link to={item.link}>
                  <div className="service__list__item__right__arrow ">
                    <img
                      src={arrowUp}
                      alt=""
                      className="service__list__item__right__arrow__black"
                    />
                    <img
                      src={arrowUpAlt}
                      alt=""
                      className="service__list__item__right__arrow__white"
                    />
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>

      <div className="service__list__mobile">
        <Swiper
          slidesPerView={1.1}
          spaceBetween={15}
          pagination={true}
          loop={true}
          modules={[Pagination]}
          slideNextClass="nextSlide"
          slidePrevClass="prevSlide"
          style={{ paddingBottom: 50 }}
        >
          {text.list?.map((item) => {
            return (
              <SwiperSlide style={{ height: "auto" }} key={item.title}>
                <div className="service__list__item">
                  <div className="service__list__item__main">
                    <div className="service__list__item__left">
                      <h3>{item.title}</h3>
                      <p>{item.disc}</p>
                    </div>
                    <div className="service__list__item__center">
                      <ul className="service__list__item__center__links">
                        {item.types.map((item) => {
                          return (
                            <li key={item}>
                              {item} <img src={arrowRight} alt="" />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className="service__list__item__right">
                    <Link to={item.link}>
                      <div className="service__list__item__right__arrow ">
                        <img
                          src={arrowUp}
                          alt=""
                          className="service__list__item__right__arrow__black"
                        />
                        <img
                          src={arrowUpAlt}
                          alt=""
                          className="service__list__item__right__arrow__white"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {/* <div className="service__list__item">
          <div className="service__list__item__main">
            <div className="service__list__item__left">
              <h3>Platform Integration</h3>
              <p>
                Introducing Platform, a groundbreaking solution for companies to
                monitor, manage, and optimize their workforce.{" "}
              </p>
            </div>
            <div className="service__list__item__center">
              <ul className="service__list__item__center__links">
                <li>
                  API Integration <img src={arrowRight} alt="" />
                </li>
                <li>
                  Custom Integration <img src={arrowRight} alt="" />
                </li>
                <li>
                  Workflow Automation <img src={arrowRight} alt="" />
                </li>
              </ul>
            </div>
          </div>

          <div className="service__list__item__right">
            <div className="service__list__item__right__arrow">
              <img
                src={arrowUp}
                alt=""
                className="service__list__item__right__arrow__black"
              />
              <img
                src={arrowUpAlt}
                alt=""
                className="service__list__item__right__arrow__white"
              />
            </div>
          </div>
        </div> */}
    </section>
  );
};

export default Service;
