import React from "react";
import check from "../assets/images/svg/check.svg";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

const Plan = ({ planList, alt, vps, email, hosting, shared }) => {
  const { t } = useTranslation();
  const text = t("plan.main", { returnObjects: true });

  return (
    <section id="pricing" className={alt ? "plan alt" : "plan"}>
      <h2 className="whyWork__main__title">
        <span>{text.title}</span>
      </h2>
      <p className="plan__disc">{text.disc}</p>

      <div className={email ? "plan__list alt" : "plan__list"}>
        {planList?.map((item) => {
          return (
            <div key={item.name} className="plan__list__item">
              <div>
                <h4>{item.name}</h4>
                <p>{item.disc}</p>

                {hosting && <p style={{ marginBottom: 0 }}>{text.itemStart}</p>}
                <div className="plan__list__item__price">
                  <span>{text.dollar}</span> <b>{item.price}</b>
                  {item.per}
                </div>
                {!hosting && (
                  <p>{vps ? text.top : shared ? text.first : text.itemStart}</p>
                )}
              </div>

              <div>
                <ul className="plan__list__item__list">
                  {item.features.map((feature) => {
                    return (
                      <li key={feature}>
                        <img src={check} alt="" /> {feature}
                      </li>
                    );
                  })}
                </ul>

                {item?.link ? (
                  <HashLink to={item?.link}>
                    <button className="plan__list__item__btn">
                      <span>{t("getStartedBtn")}</span>
                    </button>
                  </HashLink>
                ) : (
                  <button className="plan__list__item__btn">
                    <span>{t("getStartedBtn")}</span>
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Plan;
