import React, { useState } from "react";
import { Link } from "react-router-dom";
import eye from "../assets/images/svg/eye.svg";
import eyeOff from "../assets/images/svg/eyeOff.svg";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";

const Signin = () => {
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const text = t("auth.signinP", { returnObjects: true });

  const handleChange = () => {
    setChecked((curr) => !curr);
  };
  return (
    <div className="signup">
      <div className="signup__main">
        <h1>{t("auth.signin")}</h1>

        <p className="signin__dont">
          {text.new}{" "}
          <Link to="/signup">
            <span>{text.create}</span>
          </Link>
        </p>

        <div className="signup__form">
          <div className="signup__form__item">
            <input type="email" placeholder={text.email} />
          </div>

          <div className="signup__form__item alt">
            <input
              type={show ? "text" : "password"}
              placeholder={text.password}
            />
            <span onClick={() => setShow((curr) => !curr)}>
              <img src={show ? eyeOff : eye} alt="" />{" "}
              {show ? text.hide : text.show}
            </span>
          </div>

          <div className="signin__forget">
            <div className="signin__forget__left">
              <label>
                <ReactSwitch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={handleChange}
                  checked={checked}
                  className="signin__forget__left__switch"
                  offColor="#010314"
                  onColor="#010314"
                  onHandleColor="#00d3e5"
                  offHandleColor="#e7e7e7"
                />
                <span> {text.keep}</span>
              </label>
            </div>

            <Link to="/forgot-password">
              <span className="signin__forget__text">{text.forget}</span>
            </Link>
          </div>

          <div className="signup__form__btn" style={{ marginTop: 30 }}>
            <button style={{ width: "120px" }} className="btn">
              <span>{t("auth.signin")}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
