import React, { useEffect, useState } from "react";
import arrowFaq from "../assets/images/svg/arrowFaq.svg";
import arrowDownFaq from "../assets/images/svg/arrowDownFaq.svg";

const FaqItem = ({ faq, index }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (index === 0) {
      setOpen(true);
    }
  }, [index]);

  return (
    <div className={open ? "faq__list__item open" : "faq__list__item"}>
      <div className="faq__list__item__top" onClick={handleClick}>
        <h4>
          <span>
            {index < 9 ? 0 : ""}
            {index + 1}
          </span>{" "}
          {faq.question}
        </h4>
        <img
          src={open ? arrowFaq : arrowDownFaq}
          alt=""
          className="faq__list__item__top__img "
        />
      </div>
      {open && (
        <>
          <div className="faq__list__item__line"></div>
          {faq.html ? (
            <div
              className="faq__list__item__disc"
              dangerouslySetInnerHTML={{ __html: faq.answer }}
            ></div>
          ) : (
            <div className="faq__list__item__disc">{faq.answer}</div>
          )}
        </>
      )}
    </div>
  );
};

export default FaqItem;
