import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/styles/scss/main.scss";
import NotFound from "./Pages/NotFound";
import MainLayout from "./layouts/MainLayout";
import Home from "./Pages/Home";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Dedicated from "./Pages/Dedicated";
import Vps from "./Pages/Vps";
import About from "./Pages/About";
import Email from "./Pages/Email";
import Domain from "./Pages/Domain";
import "swiper/css";
import Hosting from "./Pages/Hosting";
import Web from "./Pages/Web";
import Shared from "./Pages/Shared";
import AuthLayout from "./layouts/AuthLayout";
import Signup from "./Pages/Signup";
import Signin from "./Pages/Signin";
import ResetPassword from "./Pages/ResetPassword";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "animate.css";

function App() {
  return (
    <div className="App">
      <ToastContainer position="top-right" />

      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="services/dedicated-server" element={<Dedicated />} />
            <Route path="services/vps" element={<Vps />} />
            <Route path="services/professional-email" element={<Email />} />
            <Route path="services/domain" element={<Domain />} />
            <Route path="services/hosting" element={<Hosting />} />
            <Route path="services/website-design" element={<Web />} />
            <Route path="services/shared" element={<Shared />} />
          </Route>
          <Route path="/" element={<AuthLayout />}>
            <Route path="signup" element={<Signup />} />
            <Route path="signin" element={<Signin />} />
            <Route path="forgot-password" element={<ResetPassword />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
