import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "sr"],
    fallbackLng: "en",
    // lng: "en",
    // lng: getCurrentLang(),
    interpolation: {
      escapeValue: false,
    },
    // backend: {
    //   loadPath: "/assets/locale/{{lng}}/translate.json",
    // },
    //     resources: {
    //       en: {
    //         translation: {
    //           topbar: {
    //             homeLink: "Home",
    //             aboutLink: "About Us",
    //             serviceLink: "Services",
    //             hostingLink: "Hosting",
    //             sharedLink: "Shared",
    //             vpsLink: "VPS",
    //             dedicatedLink: "Dedicated Server",
    //             domainLink: "Domain",
    //             designLink: "Website Design",
    //             emailLink: "Professional Email",
    //             contactLink: "Contact",
    //             signin: "Sign In",
    //           },

    //           getStartedBtn: "Get Started",

    //           hero: {
    //             home: {
    //               title1: "Metoda",
    //               title2: "Technology",
    //               title3: "Provider",
    //               disc: "Your digital partner for everything - from hosting to web design!",
    //               price: "from$2.99/mo*",
    //             },
    //           },

    //           service: {
    //             title1: "What",
    //             title2: "services",
    //             title3: "we offer at",
    //             title4: "Metoda?",
    //             disc: "Metoda offers a comprehensive suite of services designed to elevate your digital presence and drive success in the ever-evolving technological landscape.",
    //             list: [
    //               {
    //                 title: "Hosting Services",
    //                 disc: `Choose from our hosting services tailored to match your
    //                 requirements. Experience the reliability, security, and speed
    //                 that define our hosting solutions.`,
    //                 types: [
    //                   "Shared Hosting",
    //                   "Dedicated Server",
    //                   "Virtual Private Servers",
    //                 ],
    //                 link: "/services/hosting",
    //               },
    //               {
    //                 title: "Domain Services",
    //                 disc: `Metoda simplifies the domain registration process, ensuring you
    //                 secure a distinctive online presence effortlessly`,
    //                 types: [
    //                   "Domain Registration",
    //                   "Domain Renewal",
    //                   "Domain Transfer",
    //                 ],
    //                 link: "/services/domain",
    //               },
    //               {
    //                 title: "Website Design",
    //                 disc: `Our specialists craft custom, impactful websites tailored to
    //                 your needs. Designing a website shouldn’t be a drawn-out
    //                 ordeal—allow our experts to create a site you'll be excited to
    //                 showcase.`,
    //                 types: [
    //                   "UI/UX Design",
    //                   "E-commerce Website Design",
    //                   "Custom Website Design",
    //                 ],
    //                 link: "/services/website-design",
    //               },
    //               {
    //                 title: "Professional Email",
    //                 disc: `Easily access your email and files from any location. Build
    //                 trust with an email address that aligns with your domain.`,
    //                 types: ["Personalized Inbox", "Calendar", "Chat"],
    //                 link: "/services/professional-email",
    //               },
    //             ],
    //           },

    //           whyWork: {
    //             title1: "Why work with",
    //             title2: "Metoda?",
    //             disc: ` With a relentless focus on innovation, quality, and client
    //               satisfaction, we offer a unique blend of expertise, creativity, and
    //               collaborative spirit that sets us apart.`,
    //             list: [
    //               {
    //                 title: "Top Quality",
    //                 disc: "Design professionals that deliver top-notch masterpieces whenever you crave it!",
    //               },
    //               {
    //                 title: "Customer Support",
    //                 disc: `Availability of expert technical support 24/7 through various channels to quickly resolve issues.`,
    //               },
    //               {
    //                 title: "Super Speedy",
    //                 disc: `Transform your design into review-ready perfection in only 6-9 business days on average.`,
    //               },
    //               {
    //                 title: "Reliability",
    //                 disc: `Count on us for unwavering reliability, with secure and always available services you can trust.`,
    //               },
    //               {
    //                 title: "Flexible",
    //                 disc: `A team of flexible and
    // highly professional experts
    // on your beck and call.`,
    //               },
    //               {
    //                 title: "Transparent Pricing",
    //                 disc: `Clear and transparent pricing without hidden fees, along with competitive rates and various payment options.`,
    //               },
    //             ],
    //           },

    //           plan: {
    //             main: {
    //               title: "Plans & Pricing",
    //               disc: `We offer flexible pricing plans tailored to meet your specific needs and
    //             budget`,
    //               dollar: "$",
    //               itemStart: "Starting from",
    //             },

    //             home: {
    //               list: [
    //                 {
    //                   name: "Hosting",
    //                   disc: `Experience the excellence of our services with a handful of
    //                                 small projects`,
    //                   price: "9",
    //                   per: "/month",
    //                   features: [
    //                     "Intel Xeon-D CPU",
    //                     "RAM",
    //                     "HDD storage",
    //                     "Unmetered bandwidth",
    //                     "Cpanel included with Rooth access",
    //                   ],
    //                   link: "/services/hosting#pricing",
    //                 },
    //                 {
    //                   name: "Domains",
    //                   disc: `Experience the excellence of our services with a handful of
    //                                 small projects`,
    //                   price: "3",
    //                   per: "/year",
    //                   features: [
    //                     "Easy domain management",
    //                     "Auto-renewal",
    //                     "Domain lock",
    //                     "Domain privacy",
    //                     "Geo-location domains",
    //                   ],
    //                   alt: true,
    //                   link: "/services/domain#pricing",
    //                 },
    //                 {
    //                   name: "Website Design",
    //                   disc: `Experience the excellence of our services with a handful of
    //                                 small projects`,
    //                   price: "3.7",
    //                   per: "/hour",
    //                   features: [
    //                     "Accessible to everyone",
    //                     "Customized design solutions",
    //                     "Responsive mobile-friendly",
    //                     "User-centric approach",
    //                     "Continuous support and maintenance",
    //                   ],
    //                   link: "/services/website-design#pricing",
    //                 },
    //               ],
    //             },
    //           },

    //           faq: {
    //             main: {
    //               title1: "Frequently Asked",
    //               title2: "Questions",
    //               disc: `These questions might not be on everyone's FAQ list, but we've got your
    //         back in case you were curious.`,
    //             },
    //             home: {
    //               list: [
    //                 {
    //                   question: "How does Metoda help business owners succeed?",
    //                   answer: `Metoda offer a comprehensive suite of services to establish a successful and memorable online presence. If you already have a website, our hosting plans ensure it remains fast, secure, and accessible. Our professional email services enhance your professional image, and our online marketing tools help entrepreneurs launch SEO-friendly websites. As an all-in-one solution provider, Metoda supports your online venture with expert, personalized assistance from our Metoda Guides.`,
    //                 },
    //                 {
    //                   question: "Why choose Metoda?",
    //                   answer: `Metoda is a technology provider that offers reliable and affordable Website Hosting plans, Domains, Website Design and Platform Integration service. At Metoda, we are recognized as a top choice not only for individuals but also for small and large businesses seeking to establish their online presence. We offer round-the-clock support to those who decide to join us on this journey.`,
    //                 },
    //                 {
    //                   question: "What types of Web Hosting options are available?",
    //                   answer: `There are primarily three categories of web hosting: Dedicated Server, Shared Hosting, and VPS (Virtual Private Server) Hosting. Dedicated server allocates an entire server to a single client, Shared Hosting allows multiple clients to utilize a single server's resources collectively, and VPS Hosting offers a middle ground by providing a partitioned space on a server that simulates a dedicated server environment for its users. Each type of hosting comes with its own set of advantages and limitations, and the best fit depends on the website’s specific requirements.`,
    //                 },
    //                 // {
    //                 //   question: "Free domain name",
    //                 //   answer: `We are pleased to provide a complimentary domain name with several of our hosting packages, which can be an excellent way to reduce costs and streamline your website setup. Please review the specifics of the hosting plan to confirm if a free domain name is included, and take a moment to read our complete policy regarding free domains.`,
    //                 // },
    //                 {
    //                   question: "Can I transfer my domain to your service?",
    //                   answer: `Yes, you can transfer your domain to our service. The process typically involves obtaining an authorization (EPP) code from your current provider, unlocking the domain, and initiating the transfer request through our platform. Once the transfer is started, it may take a few days to complete, depending on the domain extension and the current provider's policies. Our support team is available to assist you through each step of the process.`,
    //                 },
    //                 {
    //                   question: "Why do I need Website for my business?",
    //                   html: true,
    //                   answer: ` Even small local businesses that serve only their local community need a website, as the internet is often the first place people turn to when searching for a product or service. A website benefits every business by enabling them to:
    //           <ul>
    //           <li> Promote and sell their products and services</li>
    //           <li>Engage with new customers and retain existing ones</li>
    //           <li>Enhance credibility</li>
    //           <li>Compete with larger companies</li>
    //           <li>Maintain control over their brand and keep their marketing strategies up-to-date</li>
    //           </ul>
    //            Furthermore, creating a website is much easier and more affordable than many might think. Metoda provides a comprehensive suite of online tools for website creation, alongside hosting, email, and marketing solutions to help businesses expand their online presence. `,
    //                 },
    //               ],
    //             },
    //           },

    //           contact: {
    //             title1: "Get In Touch",
    //             title2: "With Us",

    //             left: {
    //               title: "Let’s Create Progress Together!",
    //               disc: `We love to hear from you. Our friendly team is always here to
    //             contact with you.`,
    //               info1Name: "Company Name:",
    //               info1Data: "Metoda Belgrade",
    //               info2Name: "Call us at:",
    //               info2Data: "+381 64 1711114",
    //               info3Name: "Message us at:",
    //               info3Data: "prodaja@metoda.rs",
    //               info4Name: "Visit us at:",
    //               info4Data: "Lomina 5, Belgrade 11000, Serbia",
    //               info5Name: "Representative:",
    //               info5Data: "Vladimir Radovic",
    //               info6Name: "Reg. No:",
    //               info6Data: "21631094",
    //               info7Name: "Inception:",
    //               info7Data: "12.11.2020",
    //             },

    //             right: {
    //               disc: ` Have more questions? Our experts can provide any information you
    //             need and help you choose the perfect plan for your business needs.`,
    //               name: "Name",
    //               email: "Email Address",
    //               subject: "Subject",
    //               message: "Message",
    //               send: "Send Message",
    //               sent: "Message Sent",
    //             },
    //           },

    //           footer: {
    //             copy: "Copyright © 2024 Metoda All rights reserved",
    //             policy: "Privacy Policy",
    //             term: "Terms of Use",
    //           },
    //         },
    //       },
    //       sr: {
    //         translation: {
    //           topbar: {
    //             homeLink: "Pocetna",
    //             aboutLink: "O nama",
    //             serviceLink: "Usluge",
    //             hostingLink: "Hosting",
    //             sharedLink: "Shared",
    //             vpsLink: "VPS",
    //             dedicatedLink: "Dedicated Server",
    //             domainLink: "Domain",
    //             designLink: "Website Design",
    //             emailLink: "Professional Email",
    //             contactLink: "Kontakt",
    //             signin: "Prijavi Se",
    //           },

    //           getStartedBtn: "Započnite",

    //           hero: {
    //             home: {
    //               title1: "Metoda",
    //               title2: "Provajder",
    //               title3: "Tehnologije",
    //               disc: "Vas digitalni partner za sve – od hostinga do veb dizajna!",
    //               price: "od 300 rsd/mes*",
    //             },
    //           },

    //           service: {
    //             title1: "Koje",
    //             title2: "Usluge",
    //             title3: "Metoda",
    //             title4: "nudi?",
    //             disc: "Metoda nudi sveobuhvatan paket usluga osmišljen da unapredi vaše digitalno prisustvo i  omogući vaš uspeh u tehnološkom svetu koji se stalno razvija.",
    //             list: [
    //               {
    //                 title: "Hosting  Usluge",
    //                 disc: `Izaberite neku od naših hosting usluga prilagođenu vašim zahtevima. Iskusiste pouzdanost,  bezbednost i brzinu koje definišu naša hosting rešenja.`,
    //                 types: [
    //                   "Deljeni Hosting",
    //                   "Namenski Serveri",
    //                   "Virutelni Privatni Serveri",
    //                 ],
    //                 link: "/services/hosting",
    //               },
    //               {
    //                 title: "Registracija Domena",
    //                 disc: `Metoda pojednostavljuje ceo proces  registracije domena, osiguravajući  da bez napora obezbedite vaše prepoznatljivo onlajn pristustvo.`,
    //                 types: [
    //                   "Registracija Domena",
    //                   "Obnova Domena",
    //                   "Transfer Domena",
    //                 ],
    //                 link: "/services/domain",
    //               },
    //               {
    //                 title: "Websajt  Dizajn",
    //                 disc: `Naši dizajn specijalisti kreiraju prilagođene i  moderne veb sajtove prema vašim potrebama.  Dizajniranje web sajta ne bi trebalo da bude dugotrajan proces – dozvolite našim ekspertima da naprave web sajt  koji ćete  sa radošću da podelite sa svetom.`,
    //                 types: [
    //                   "UI/UX Dizajn",
    //                   "Dizajn veb sajtova za E-Trgovinu",
    //                   "Prilagodjen dizajn veb sajtova",
    //                 ],
    //                 link: "/services/website-design",
    //               },
    //               {
    //                 title: "Profesioanlni  Email",
    //                 disc: `Lako pristupite svojoj elektronsoj pošti i fajlovima sa bilo koje lokacije. Izgradite poverenje sa adresom elektronske pošte koja je usklađena sa vašim domenom.`,
    //                 types: ["Personalizovani Inboks", "Kalendar", "Čet"],
    //                 link: "/services/professional-email",
    //               },
    //             ],
    //           },

    //           whyWork: {
    //             title1: "Zašto raditi sa",
    //             title2: "Metodom?",
    //             disc: `Sa nepokolebljivim fokusom na inovacije, kvalitet i zadovoljstvo klijenata, nudimo jedinstvenu kombinaciju stručnosti, kreativnosti i duha saradnje koji nas izdvaja od drugih.`,
    //             list: [
    //               {
    //                 title: "Vrhunski Kvalitet",
    //                 disc: "Stručnjaci za dizajn koji isporučuju moderna rešenja kada god ih poželite!",
    //               },
    //               {
    //                 title: "Koristnička Podrška",
    //                 disc: "Dostupnost stručne tehničke podrške 247 putem različitih kanala za brzo rešavanje problema.",
    //               },
    //               {
    //                 title: "Super Brzine",
    //                 disc: "Pretvorite svoj dizajn u savršenstvo spremno za pregled u proseku od 6-9 radnih dana.",
    //               },
    //               {
    //                 title: "Pouzdanost",
    //                 disc: "Računajte na nas za nepokolebljivu pouzdanost, sa sigurnim i uvek dostupnim uslugama kojima možete verovati.",
    //               },
    //               {
    //                 title: "Fleksibilnost",
    //                 disc: "Tim fleksibilnih i veoma profesionalnih sturčnjaka Vama uvek na raspolaganju.",
    //               },
    //               {
    //                 title: "Transparentnost i Fer Cena:",
    //                 disc: "Jasno i transparentno određivanje cena bez skrivenih troškova, uz konkurentne cene i različite opcije plaćanja, omogućava klijentima da tačno znaju šta dobijaju za svoj novac.",
    //               },
    //             ],
    //           },

    //           plan: {
    //             main: {
    //               title: "Planovi & Cene",
    //               disc: `Nudimo fleksibilne planove cena prilagođene
    //   vašim specifičnim potrebama i budžetu.`,
    //               dollar: "RSD",
    //               itemStart: "počevši od",
    //             },

    //             home: {
    //               list: [
    //                 {
    //                   name: "Hosting",
    //                   disc: `Napravljen za jednostavne veb sajtove i blogove.`,
    //                   price: "300",
    //                   per: "/ mesec",
    //                   features: [
    //                     "Intel Xeon-D CPU",
    //                     "RAM",
    //                     "HDD skladište",
    //                     "Neograničena propusnost",
    //                     "CPanel uključen sa root pristupom",
    //                   ],
    //                   link: "/services/hosting#pricing",
    //                 },
    //                 {
    //                   name: "Domeni",
    //                   disc: `Vaš digitalni identitet počinje ovde.`,
    //                   price: "315",
    //                   per: "/godišnje",
    //                   features: [
    //                     "Easy domain management",
    //                     "Automatsko obnavljanje",
    //                     "Zaključavanje domena",
    //                     "Privatnost domena",
    //                     "Geo-lokacija domena",
    //                   ],
    //                   alt: true,
    //                   link: "/services/domain#pricing",
    //                 },
    //                 {
    //                   name: "Vebsajt Dizajn",
    //                   disc: `Stvarajte svoju online priču.`,
    //                   price: "53,500",
    //                   per: "/mesec",
    //                   features: [
    //                     "Intel Xeon-D CPU",
    //                     "RAM",
    //                     "HDD skladište",
    //                     "Neograničena propusnost",
    //                     "CPanel uključen sa root pristupom",
    //                   ],
    //                   link: "/services/website-design#pricing",
    //                 },
    //               ],
    //             },
    //           },

    //           faq: {
    //             main: {
    //               title1: "Često Postavljana",
    //               title2: "Pitanja",
    //               disc: `Ova pitanja možda nisu na spisku FAQ-a svima, ali smo tu da vam pomognemo ako ste radoznali.`,
    //             },
    //             home: {
    //               list: [
    //                 {
    //                   question: "Šta je Veb Hosting?",
    //                   answer: `Metoda nudi sveobuhvatan paket usluga za uspostavljanje uspešne i nezaboravne online prisutnosti. Ako već imate veb sajt, naši hosting planovi obezbeđuju da on ostane brz, bezbedan i dostupan. Naše profesionalne e-mail usluge poboljšavaju vaš profesionalni imidž, a naši alati za online marketing pomažu preduzetnicima da pokrenu veb sajtove prilagođene SEO-u. Kao rešenje sve u jednom, Metoda podržava vašu online avanturu sa stručnom, personalizovanom pomoći naših vodiča.`,
    //                 },
    //                 {
    //                   question: "Zašto izabrati Metodu?",
    //                   answer: `There are primarily three categories of web hosting: Dedicated Server, Shared Hosting, and VPS (Virtual Private Server) Hosting. Dedicated server allocates an entire server to a single client, Shared Hosting allows multiple clients to utilize a single server's resources collectively, and VPS Hosting offers a middle ground by providing a partitioned space on a server that simulates a dedicated server environment for its users. Each type of hosting comes with its own set of advantages and limitations, and the best fit depends on the website’s specific requirements.`,
    //                 },
    //                 {
    //                   question:
    //                     "Koje vrste opcija za hosting veb sajtova su dostupne?",
    //                   answer: `Postoje tri glavne kategorije hostinga veb sajtova: Namenski server, Deljeni hosting i VPS (Virtualni Privatni Server) hosting. Namenski server dodeljuje ceo server jednom klijentu, Deljeni hosting omogućava više klijenata da zajednički koriste resurse jednog servera, dok VPS hosting nudi srednje rešenje pružanjem particionisanog prostora na serveru koji simulira okruženje namenskog servera za svoje korisnike. Svaka vrsta hostinga dolazi sa svojim prednostima i ograničenjima, a najbolji izbor zavisi od specifičnih zahteva veb sajta i Vas samih.`,
    //                 },
    //                 {
    //                   question: "Besplatan naziv domena.",
    //                   answer: `Sa zadovoljstvom nudimo besplatan naziv domena uz nekoliko naših hosting paketa, što može biti odličan način da smanjite troškove i pojednostavite postavljanje vašeg veb sajta. Molimo vas da pregledate specifikacije hosting plana kako biste potvrdili da li je besplatan naziv domena uključen, i odvojite trenutak da pročitate našu kompletnu politiku u vezi sa besplatnim domenima.`,
    //                 },
    //                 {
    //                   question: "Zašto mi je potreban veb sajt za moje preduzeće?",
    //                   html: true,
    //                   answer: `Čak i male lokalne firme koje služe samo svojoj lokalnoj zajednici treba da imaju veb sajt, jer je internet često prvo mesto na kojem ljudi traže proizvod ili uslugu. Veb sajt koristi svakoj firmi omogućavajući
    //                   im da:
    //                   <ul>
    //                     <li> Promovišu i prodaju svoje proizvode i usluge</li>
    //                     <li>Angažuju nove kupce i zadrže postojeće</li>
    //                     <li>Povećaju kredibilitet</li>
    //                     <li>Konkuriraju većim kompanijama</li>
    //                     <li>Održe kontrolu nad svojim brendom i ažuriraju svoje marketinške strategije</li>
    //                   </ul>
    //                   Pored toga, kreiranje veb sajta je mnogo lakše i pristupačnije nego što mnogi misle. Metoda pruža sveobuhvatan paket online alata za kreiranje veb sajta, zajedno sa hostingom, e-mailom i rešenjima za marketing kako bi pomogla firmama da prošire svoju online prisutnost.
    //                   `,
    //                 },
    //               ],
    //             },
    //           },

    //           contact: {
    //             title1: "Kontaktirajte",
    //             title2: "Nas",

    //             left: {
    //               title: "Napravimo Napredak Zajedno!",
    //               disc: `Rado bismo vas čuli. Naš tim je uvek tu da stupi u kontakt sa vama.`,
    //               info1Name: "Ime Kompanije:",
    //               info1Data: "Metoda Belgrade",
    //               info2Name: "Pozovi nas na:",
    //               info2Data: "+381 64 1711114",
    //               info3Name: "Pošaljite nam poruku na:",
    //               info3Data: "prodaja@metoda.rs",
    //               info4Name: "Poseti nas na:",
    //               info4Data: "Lomina 5, Belgrade 11000, Serbia",
    //               info5Name: "Representative:",
    //               info5Data: "Vladimir Radovic",
    //               info6Name: "Reg. No:",
    //               info6Data: "21631094",
    //               info7Name: "Inception:",
    //               info7Data: "12.11.2020",
    //             },

    //             right: {
    //               disc: `Imate još pitanja? Naši stručnjaci mogu da pruže sve informacije koje vam trebaju i pomognu vam da izaberete savršen paket za vaše potrebe.`,
    //               name: "Ime",
    //               email: "Email Adresa",
    //               subject: "Predmet",
    //               message: "Poruka",
    //               send: "Pošalji Poruku",
    //               sent: "Poruka poslata",
    //             },
    //           },

    //           footer: {
    //             copy: "Copyright © 2024 Metoda All rights reserved",
    //             policy: "Privacy Policy",
    //             term: "Uslovi koriscenja",
    //           },
    //         },
    //       },
    //     },
  });

export default i18n;
